import React from 'react';
import { OAuthProvider } from './contexts/OAuth';
import { Provider as ReduxProvider } from 'react-redux';
import AppRoutes from './routes';
import store from './redux';

const App: React.FC = () => {
  return (
    <ReduxProvider store={store}>
      <OAuthProvider>
        <AppRoutes />
      </OAuthProvider>
    </ReduxProvider>
  );
};

export default App;
