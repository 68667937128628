import { Reducer } from 'redux';
import { AccountState, AccountTypes, AccountActionTypes } from './types';

const initialState: AccountState = {
  accounts: [],
  accountsList: [],
  accountsFullList: [],
  accountsTransferList: []
};

type ReducerOptions = Reducer<AccountState, AccountActionTypes>;

const reducer: ReducerOptions = (state = initialState, action): AccountState => {
  switch (action.type) {
    case AccountTypes.GET_ACCOUNTS:
      return {
        ...state,
        accounts: action.payload
      };
    case AccountTypes.UNSET_ACCOUNTS:
      return {
        ...state,
        accounts: action.payload
      };
    case AccountTypes.SET_ACCOUNTS_META:
      return {
        ...state,
        meta: action.payload
      };
    case AccountTypes.UNSET_ACCOUNTS_META:
      return {
        ...state,
        meta: undefined
      };
    case AccountTypes.GET_ACCOUNTS_LIST:
      return {
        ...state,
        accountsList: action.payload
      };
    case AccountTypes.UNSET_ACCOUNTS_LIST:
      return {
        ...state,
        accountsList: action.payload
      };
    case AccountTypes.GET_ACCOUNTS_FULL_LIST:
      return {
        ...state,
        accountsFullList: action.payload
      };
    case AccountTypes.UNSET_ACCOUNTS_FULL_LIST:
      return {
        ...state,
        accountsFullList: action.payload
      };
    case AccountTypes.GET_ACCOUNTS_TRANSFER_LIST:
      return {
        ...state,
        accountsTransferList: action.payload
      };
    case AccountTypes.UNSET_ACCOUNTS_TRANSFER_LIST:
      return {
        ...state,
        accountsTransferList: action.payload
      };
    case AccountTypes.GET_ACCOUNT:
      return {
        ...state,
        account: action.payload
      };
    case AccountTypes.CREATE_ACCOUNT:
      return {
        ...state,
        account: action.payload
      };
    case AccountTypes.UPDATE_ACCOUNT:
      return {
        ...state,
        account: action.payload
      };
    case AccountTypes.DELETE_ACCOUNT:
      return {
        ...state,
        account: action.payload
      };
    case AccountTypes.SHOW_ACCOUNT_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case AccountTypes.HIDE_ACCOUNT_LOADING:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export default reducer;
