export interface DashboardState {
  readonly accountsStatistics: [];
  readonly loading?: boolean;
}

export enum DashboardTypes {
  GET_ACCOUNTS_STATISTICS = 'GET_ACCOUNTS_STATISTICS',
  SHOW_DASHBOARD_LOADING = 'SHOW_DASHBOARD_LOADING',
  HIDE_DASHBOARD_LOADING = 'HIDE_DASHBOARD_LOADING'
}

export interface GetDashboardDataAction {
  type: typeof DashboardTypes.GET_ACCOUNTS_STATISTICS;
  payload: [];
}

export interface ShowDashboardLoadingAction {
  type: typeof DashboardTypes.SHOW_DASHBOARD_LOADING;
  payload: true;
}

export interface HideDashboardLoadingAction {
  type: typeof DashboardTypes.HIDE_DASHBOARD_LOADING;
}

export type DashboardActionTypes = GetDashboardDataAction | ShowDashboardLoadingAction | HideDashboardLoadingAction;
