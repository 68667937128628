import { Reducer } from 'redux';
import { UserState, UserTypes, UserActionTypes } from './types';

const initialState: UserState = {
  users: [],
  usersList: []
};

type ReducerOptions = Reducer<UserState, UserActionTypes>;

const reducer: ReducerOptions = (state = initialState, action): UserState => {
  switch (action.type) {
    case UserTypes.GET_USERS:
      return {
        ...state,
        users: action.payload
      };
    case UserTypes.UNSET_USERS:
      return {
        ...state,
        users: action.payload
      };
    case UserTypes.SET_USERS_META:
      return {
        ...state,
        meta: action.payload
      };
    case UserTypes.UNSET_USERS_META:
      return {
        ...state,
        meta: undefined
      };
    case UserTypes.GET_USERS_LIST:
      return {
        ...state,
        usersList: action.payload
      };
    case UserTypes.UNSET_USERS_LIST:
      return {
        ...state,
        usersList: action.payload
      };
    case UserTypes.GET_USER:
      return {
        ...state,
        user: action.payload
      };
    case UserTypes.GET_PROFILE:
      return {
        ...state,
        profile: action.payload
      };
    case UserTypes.CREATE_USER:
      return {
        ...state,
        user: action.payload
      };
    case UserTypes.UPDATE_USER:
      return {
        ...state,
        user: action.payload
      };
    case UserTypes.DELETE_USER:
      return {
        ...state,
        user: action.payload
      };
    case UserTypes.SHOW_USER_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case UserTypes.HIDE_USER_LOADING:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export default reducer;
