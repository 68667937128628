import { IAccount, IPaginatorMeta } from '../../types';

export interface AccountState {
  readonly accounts: IAccount[];
  readonly accountsList: IAccount[];
  readonly accountsFullList: IAccount[];
  readonly accountsTransferList: IAccount[];
  readonly account?: IAccount;
  readonly meta?: IPaginatorMeta;
  readonly loading?: boolean;
}

export enum AccountTypes {
  GET_ACCOUNTS = 'GET_ACCOUNTS',
  UNSET_ACCOUNTS = 'UNSET_ACCOUNTS',
  SET_ACCOUNTS_META = 'SET_ACCOUNTS_META',
  UNSET_ACCOUNTS_META = 'UNSET_ACCOUNTS_META',
  GET_ACCOUNTS_LIST = 'GET_ACCOUNTS_LIST',
  UNSET_ACCOUNTS_LIST = 'UNSET_ACCOUNTS_LIST',
  GET_ACCOUNTS_FULL_LIST = 'GET_ACCOUNTS_FULL_LIST',
  UNSET_ACCOUNTS_FULL_LIST = 'UNSET_ACCOUNTS_FULL_LIST',
  GET_ACCOUNTS_TRANSFER_LIST = 'GET_ACCOUNTS_TRANSFER_LIST',
  UNSET_ACCOUNTS_TRANSFER_LIST = 'UNSET_ACCOUNTS_TRANSFER_LIST',
  GET_ACCOUNT = 'GET_ACCOUNT',
  CREATE_ACCOUNT = 'CREATE_ACCOUNT',
  UPDATE_ACCOUNT = 'UPDATE_ACCOUNT',
  DELETE_ACCOUNT = 'DELETE_ACCOUNT',
  SHOW_ACCOUNT_LOADING = 'SHOW_ACCOUNT_LOADING',
  HIDE_ACCOUNT_LOADING = 'HIDE_ACCOUNT_LOADING'
}

export interface GetAccountsAction {
  type: typeof AccountTypes.GET_ACCOUNTS;
  payload: IAccount[];
}

export interface UnsetAccountsAction {
  type: typeof AccountTypes.UNSET_ACCOUNTS;
  payload: IAccount[];
}

export interface SetAccountsMetaAction {
  type: typeof AccountTypes.SET_ACCOUNTS_META;
  payload: IPaginatorMeta;
}

export interface UnsetAccountsMetaAction {
  type: typeof AccountTypes.UNSET_ACCOUNTS_META;
  payload: null;
}

export interface GetAccountsListAction {
  type: typeof AccountTypes.GET_ACCOUNTS_LIST;
  payload: IAccount[];
}

export interface UnsetAccountsListAction {
  type: typeof AccountTypes.UNSET_ACCOUNTS_LIST;
  payload: IAccount[];
}

export interface GetAccountsFullListAction {
  type: typeof AccountTypes.GET_ACCOUNTS_FULL_LIST;
  payload: IAccount[];
}

export interface UnsetAccountsFullListAction {
  type: typeof AccountTypes.UNSET_ACCOUNTS_FULL_LIST;
  payload: IAccount[];
}

export interface GetAccountsTransferListAction {
  type: typeof AccountTypes.GET_ACCOUNTS_TRANSFER_LIST;
  payload: IAccount[];
}

export interface UnsetAccountsTransferListAction {
  type: typeof AccountTypes.UNSET_ACCOUNTS_TRANSFER_LIST;
  payload: IAccount[];
}

export interface GetAccountAction {
  type: typeof AccountTypes.GET_ACCOUNT;
  payload: IAccount;
}

export interface CreateAccountAction {
  type: typeof AccountTypes.CREATE_ACCOUNT;
  payload: IAccount;
}

export interface UpdateAccountAction {
  type: typeof AccountTypes.UPDATE_ACCOUNT;
  payload: IAccount;
}

export interface DeleteAccountAction {
  type: typeof AccountTypes.DELETE_ACCOUNT;
  payload: IAccount;
}

export interface ShowAccountLoadingAction {
  type: typeof AccountTypes.SHOW_ACCOUNT_LOADING;
  payload: true;
}

export interface HideAccountLoadingAction {
  type: typeof AccountTypes.HIDE_ACCOUNT_LOADING;
}

export type AccountActionTypes =
  | GetAccountsAction
  | UnsetAccountsAction
  | SetAccountsMetaAction
  | UnsetAccountsMetaAction
  | GetAccountsListAction
  | UnsetAccountsListAction
  | GetAccountsFullListAction
  | UnsetAccountsFullListAction
  | GetAccountsTransferListAction
  | UnsetAccountsTransferListAction
  | GetAccountAction
  | CreateAccountAction
  | UpdateAccountAction
  | DeleteAccountAction
  | ShowAccountLoadingAction
  | HideAccountLoadingAction;
