import { FC } from 'react';

const Preloader: FC = () => {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 z-50 overflow-hidden bg-black opacity-60 flex flex-col items-center justify-center w-full h-full">
      <div className="preloader ease-linear opacity-100 rounded-full border-4 border-t-4 border-gray-100 h-12 w-12 mb-4 mt-4" />
      <h2 className="text-center text-white text-xl font-semibold">Завантаження...</h2>
    </div>
  );
};

export default Preloader;
