import { ISubcategory, IPaginatorMeta } from '../../types';

export interface SubcategoryState {
  readonly subcategories: ISubcategory[];
  readonly subcategoriesList: ISubcategory[];
  readonly subcategory?: ISubcategory;
  readonly meta?: IPaginatorMeta;
  readonly loading?: boolean;
}

export enum SubcategoryTypes {
  GET_SUBCATEGORIES = 'GET_SUBCATEGORIES',
  UNSET_SUBCATEGORIES = 'UNSET_SUBCATEGORIES',
  SET_SUBCATEGORIES_META = 'SET_SUBCATEGORIES_META',
  UNSET_SUBCATEGORIES_META = 'UNSET_SUBCATEGORIES_META',
  GET_SUBCATEGORIES_LIST = 'GET_SUBCATEGORIES_LIST',
  UNSET_SUBCATEGORIES_LIST = 'UNSET_SUBCATEGORIES_LIST',
  GET_SUBCATEGORY = 'GET_SUBCATEGORY',
  CREATE_SUBCATEGORY = 'CREATE_SUBCATEGORY',
  UPDATE_SUBCATEGORY = 'UPDATE_SUBCATEGORY',
  DELETE_SUBCATEGORY = 'DELETE_SUBCATEGORY',
  SHOW_SUBCATEGORY_LOADING = 'SHOW_SUBCATEGORY_LOADING',
  HIDE_SUBCATEGORY_LOADING = 'HIDE_SUBCATEGORY_LOADING'
}

export interface GetSubcategoriesAction {
  type: typeof SubcategoryTypes.GET_SUBCATEGORIES;
  payload: ISubcategory[];
}

export interface UnsetSubcategoriesAction {
  type: typeof SubcategoryTypes.UNSET_SUBCATEGORIES;
  payload: ISubcategory[];
}

export interface SetSubcategoriesMetaAction {
  type: typeof SubcategoryTypes.SET_SUBCATEGORIES_META;
  payload: IPaginatorMeta;
}

export interface UnsetSubcategoriesMetaAction {
  type: typeof SubcategoryTypes.UNSET_SUBCATEGORIES_META;
  payload: null;
}

export interface GetSubcategoriesListAction {
  type: typeof SubcategoryTypes.GET_SUBCATEGORIES_LIST;
  payload: ISubcategory[];
}

export interface UnsetSubcategoriesListAction {
  type: typeof SubcategoryTypes.UNSET_SUBCATEGORIES_LIST;
  payload: ISubcategory[];
}

export interface GetSubcategoryAction {
  type: typeof SubcategoryTypes.GET_SUBCATEGORY;
  payload: ISubcategory;
}

export interface CreateSubcategoryAction {
  type: typeof SubcategoryTypes.CREATE_SUBCATEGORY;
  payload: ISubcategory;
}

export interface UpdateSubcategoryAction {
  type: typeof SubcategoryTypes.UPDATE_SUBCATEGORY;
  payload: ISubcategory;
}

export interface DeleteSubcategoryAction {
  type: typeof SubcategoryTypes.DELETE_SUBCATEGORY;
  payload: ISubcategory;
}

export interface ShowSubcategoryLoadingAction {
  type: typeof SubcategoryTypes.SHOW_SUBCATEGORY_LOADING;
  payload: true;
}

export interface HideSubcategoryLoadingAction {
  type: typeof SubcategoryTypes.HIDE_SUBCATEGORY_LOADING;
}

export type SubcategoryActionTypes =
  | GetSubcategoriesAction
  | UnsetSubcategoriesAction
  | SetSubcategoriesMetaAction
  | UnsetSubcategoriesMetaAction
  | GetSubcategoriesListAction
  | UnsetSubcategoriesListAction
  | GetSubcategoryAction
  | CreateSubcategoryAction
  | UpdateSubcategoryAction
  | DeleteSubcategoryAction
  | ShowSubcategoryLoadingAction
  | HideSubcategoryLoadingAction;
