import { IAccount, IAccountReport, IAccountReportEntry, ICategoryReport } from '../../types';

export interface ReportState {
  readonly account?: IAccount;
  readonly accountReport: IAccountReport[];
  readonly accountReportEntry?: IAccountReportEntry;
  readonly categoryReport: ICategoryReport[];
  readonly categoryReportTotal: string;
  readonly loading?: boolean;
}

export enum ReportTypes {
  SET_ACCOUNT = 'SET_ACCOUNT',
  UNSET_ACCOUNT = 'UNSET_ACCOUNT',
  SET_ACCOUNT_REPORT = 'SET_ACCOUNT_REPORT',
  UNSET_ACCOUNT_REPORT = 'UNSET_ACCOUNT_REPORT',
  SET_ACCOUNT_REPORT_ENTRY = 'SET_ACCOUNT_REPORT_ENTRY',
  UNSET_ACCOUNT_REPORT_ENTRY = 'UNSET_ACCOUNT_REPORT_ENTRY',
  SET_CATEGORY_REPORT = 'SET_CATEGORY_REPORT',
  UNSET_CATEGORY_REPORT = 'UNSET_CATEGORY_REPORT',
  SET_CATEGORY_REPORT_TOTAL = 'SET_CATEGORY_REPORT_TOTAL',
  UNSET_CATEGORY_REPORT_TOTAL = 'UNSET_CATEGORY_REPORT_TOTAL',
  SHOW_REPORT_LOADING = 'SHOW_REPORT_LOADING',
  HIDE_REPORT_LOADING = 'HIDE_REPORT_LOADING'
}

export interface SetAccountAction {
  type: typeof ReportTypes.SET_ACCOUNT;
  payload: IAccount;
}

export interface UnsetAccountAction {
  type: typeof ReportTypes.UNSET_ACCOUNT;
  payload: null;
}

export interface SetAccountReportAction {
  type: typeof ReportTypes.SET_ACCOUNT_REPORT;
  payload: IAccountReport[];
}

export interface UnsetAccountReportAction {
  type: typeof ReportTypes.UNSET_ACCOUNT_REPORT;
  payload: IAccountReport[];
}

export interface SetAccountReportEntryAction {
  type: typeof ReportTypes.SET_ACCOUNT_REPORT_ENTRY;
  payload: IAccountReportEntry;
}

export interface UnsetAccountReportEntryAction {
  type: typeof ReportTypes.UNSET_ACCOUNT_REPORT_ENTRY;
  payload: null;
}

export interface SetCategoryReportAction {
  type: typeof ReportTypes.SET_CATEGORY_REPORT;
  payload: ICategoryReport[];
}

export interface UnsetCategoryReportAction {
  type: typeof ReportTypes.UNSET_CATEGORY_REPORT;
  payload: ICategoryReport[];
}

export interface SetCategoryReportTotalAction {
  type: typeof ReportTypes.SET_CATEGORY_REPORT_TOTAL;
  payload: string;
}

export interface UnsetCategoryReportTotalAction {
  type: typeof ReportTypes.UNSET_CATEGORY_REPORT_TOTAL;
  payload: string;
}

export interface ShowReportLoadingAction {
  type: typeof ReportTypes.SHOW_REPORT_LOADING;
  payload: true;
}

export interface HideReportLoadingAction {
  type: typeof ReportTypes.HIDE_REPORT_LOADING;
}

export type ReportActionTypes =
  | SetAccountAction
  | UnsetAccountAction
  | SetAccountReportAction
  | UnsetAccountReportAction
  | SetAccountReportEntryAction
  | UnsetAccountReportEntryAction
  | SetCategoryReportAction
  | UnsetCategoryReportAction
  | SetCategoryReportTotalAction
  | UnsetCategoryReportTotalAction
  | ShowReportLoadingAction
  | HideReportLoadingAction;
