import React from 'react';
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Bars3BottomLeftIcon, ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline';

import { HomeIcon, UserIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../redux';
import Breadcrumb from '../Breadcrumb';

interface IProps {
  setSidebarOpen: (payload: boolean) => void;
}

const Header: React.FC<IProps> = ({ setSidebarOpen }) => {
  const profile = useSelector((state: ApplicationState) => state.userReducer.profile);
  const { logout } = useAuth();
  const navigate = useNavigate();
  return (
    <>
      <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
        <button
          type="button"
          className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
        </button>
        <button
          type="button"
          className="px-4 border-r border-gray-200 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
          onClick={() => navigate('/dashboard')}
        >
          <HomeIcon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex-1 px-4 flex justify-between">
          <Breadcrumb />
          <div className="ml-4 flex items-center md:ml-6">
            {/*<button*/}
            {/*  type="button"*/}
            {/*  className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"*/}
            {/*>*/}
            {/*  <span className="sr-only">View notifications</span>*/}
            {/*  <BellIcon className="h-6 w-6" aria-hidden="true" />*/}
            {/*</button>*/}

            {/* Profile dropdown */}
            <div className="ml-4">
              <p className="userName text-sm font-medium text-right text-gray-700 dark:text-gray-700">
                {profile ? `${profile.first_name} ${profile.last_name}` : ''}
              </p>
              <p className="userRole text-xs font-normal text-right text-gray-500 dark:text-gray-500">
                {moment().format('DD.MM.YYYY')}
              </p>
            </div>
            <Menu as="div" className="ml-3 relative">
              <div>
                <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <span className="sr-only">Open user menu</span>
                  {profile?.photo ? (
                    <img className="h-8 w-8 rounded-full" src={profile?.photo} alt="" />
                  ) : (
                    <svg className="h-8 w-8 rounded-full" fill="grey" viewBox="0 0 24 24">
                      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  )}
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="/profile/"
                        className={clsx(
                          active
                            ? 'bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-gray-50 rounded-t-md'
                            : 'text-gray-700',
                          'group flex items-center px-4 py-2 text-sm'
                        )}
                      >
                        <UserIcon
                          className="mr-3 h-5 w-5 text-gray-400 dark:text-gray-400 dark:group-hover:text-gray-100 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                        Профіль
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to=""
                        className={clsx(
                          active
                            ? 'bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-gray-50 rounded-b-md'
                            : 'text-gray-700',
                          'group flex items-center px-4 py-2 text-sm cursor-pointer'
                        )}
                        onClick={() => logout()}
                      >
                        <ArrowRightOnRectangleIcon
                          className="mr-3 h-5 w-5 text-gray-400 dark:text-gray-400 dark:group-hover:text-gray-100 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                        Вийти
                      </Link>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
