import { Reducer } from 'redux';
import { CategoryState, CategoryTypes, CategoryActionTypes } from './types';

const initialState: CategoryState = {
  categories: [],
  categoriesList: []
};

type ReducerOptions = Reducer<CategoryState, CategoryActionTypes>;

const reducer: ReducerOptions = (state = initialState, action): CategoryState => {
  switch (action.type) {
    case CategoryTypes.GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload
      };
    case CategoryTypes.UNSET_CATEGORIES:
      return {
        ...state,
        categories: action.payload
      };
    case CategoryTypes.SET_CATEGORIES_META:
      return {
        ...state,
        meta: action.payload
      };
    case CategoryTypes.UNSET_CATEGORIES_META:
      return {
        ...state,
        meta: undefined
      };
    case CategoryTypes.GET_CATEGORIES_LIST:
      return {
        ...state,
        categoriesList: action.payload
      };
    case CategoryTypes.UNSET_CATEGORIES_LIST:
      return {
        ...state,
        categoriesList: action.payload
      };
    case CategoryTypes.GET_CATEGORY:
      return {
        ...state,
        category: action.payload
      };
    case CategoryTypes.CREATE_CATEGORY:
      return {
        ...state,
        category: action.payload
      };
    case CategoryTypes.UPDATE_CATEGORY:
      return {
        ...state,
        category: action.payload
      };
    case CategoryTypes.DELETE_CATEGORY:
      return {
        ...state,
        category: action.payload
      };
    case CategoryTypes.SHOW_CATEGORY_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case CategoryTypes.HIDE_CATEGORY_LOADING:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export default reducer;
