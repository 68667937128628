import Axios, { AxiosInstance } from 'axios';

const baseURL = process.env.REACT_APP_API_URL;

const HTTP_HEADERS = {
  default: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  },
  file: {
    'Content-Type': 'multipart/form-data',
    Accept: 'application/json'
  },
  alphanumeric: {
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: 'application/json'
  }
};

const api: AxiosInstance = Axios.create({
  baseURL: `${baseURL}/api`,
  timeout: 200000,
  headers: HTTP_HEADERS.default,
  withCredentials: true
});

api.interceptors.request.use(
  async config => {
    const token = localStorage.getItem('accessToken');

    if (config && config.headers && token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => response,
  async error => {
    if (error?.response?.status === 401 && error?.response?.data?.message === 'Unauthenticated.') {
      const token = localStorage.getItem('accessToken');
      if (!token) return Promise.reject(error);

      localStorage.removeItem('accessToken');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export { api };
