import React from 'react';
import { Link } from 'react-router-dom';
import { HomeIcon } from '@heroicons/react/24/solid';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../redux';

const Breadcrumb: React.FC = () => {
  const items = useSelector((state: ApplicationState) => state.breadcrumbReducer.breadcrumbs);
  return (
    <div className="flex-1 flex">
      <nav className="hidden md:flex" aria-label="Breadcrumb">
        <ol role="list" className="flex items-center space-x-4">
          <li>
            <div>
              <Link to="/dashboard" className="text-gray-400 hover:text-gray-500">
                <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
              </Link>
            </div>
          </li>
          {items?.length > 0 && (
            <>
              {items?.map((item, itemIndex) => (
                <li key={itemIndex}>
                  <div className="flex items-center">
                    <svg
                      className="flex-shrink-0 h-5 w-5 text-gray-300"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                    </svg>
                    {item.url ? (
                      <>
                        <Link to={item.url} className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                          {item.title}
                        </Link>
                      </>
                    ) : (
                      <span className="ml-4 text-sm font-medium text-gray-500">{item.title}</span>
                    )}
                  </div>
                </li>
              ))}
            </>
          )}
        </ol>
      </nav>
    </div>
  );
};

export default Breadcrumb;
