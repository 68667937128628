import { IExpense, IExpenseLastSavedData, IPaginatorMeta } from '../../types';

export interface ExpenseState {
  readonly expenses: IExpense[];
  readonly expense?: IExpense;
  readonly meta?: IPaginatorMeta;
  readonly loading?: boolean;
  readonly lastSavedData: IExpenseLastSavedData;
}

export enum ExpenseTypes {
  GET_EXPENSES = 'GET_EXPENSES',
  UNSET_EXPENSES = 'UNSET_EXPENSES',
  SET_EXPENSES_META = 'SET_EXPENSES_META',
  UNSET_EXPENSES_META = 'UNSET_EXPENSES_META',
  GET_EXPENSE = 'GET_EXPENSE',
  CREATE_EXPENSE = 'CREATE_EXPENSE',
  UPDATE_EXPENSE = 'UPDATE_EXPENSE',
  DELETE_EXPENSE = 'DELETE_EXPENSE',
  SHOW_EXPENSE_LOADING = 'SHOW_EXPENSE_LOADING',
  HIDE_EXPENSE_LOADING = 'HIDE_EXPENSE_LOADING',
  SET_EXPENSE_LAST_SAVED_DATA = 'SET_EXPENSE_LAST_SAVED_DATA'
}

export interface GetExpensesAction {
  type: typeof ExpenseTypes.GET_EXPENSES;
  payload: IExpense[];
}

export interface UnsetExpensesAction {
  type: typeof ExpenseTypes.UNSET_EXPENSES;
  payload: IExpense[];
}

export interface SetExpensesMetaAction {
  type: typeof ExpenseTypes.SET_EXPENSES_META;
  payload: IPaginatorMeta;
}

export interface UnsetExpensesMetaAction {
  type: typeof ExpenseTypes.UNSET_EXPENSES_META;
  payload: null;
}

export interface GetExpenseAction {
  type: typeof ExpenseTypes.GET_EXPENSE;
  payload: IExpense;
}

export interface CreateExpenseAction {
  type: typeof ExpenseTypes.CREATE_EXPENSE;
  payload: IExpense;
}

export interface UpdateExpenseAction {
  type: typeof ExpenseTypes.UPDATE_EXPENSE;
  payload: IExpense;
}

export interface DeleteExpenseAction {
  type: typeof ExpenseTypes.DELETE_EXPENSE;
  payload: IExpense;
}

export interface ShowExpenseLoadingAction {
  type: typeof ExpenseTypes.SHOW_EXPENSE_LOADING;
  payload: true;
}

export interface HideExpenseLoadingAction {
  type: typeof ExpenseTypes.HIDE_EXPENSE_LOADING;
}

export interface SetExpenseLastSavedDataAction {
  type: typeof ExpenseTypes.SET_EXPENSE_LAST_SAVED_DATA;
  payload: IExpenseLastSavedData;
}

export type ExpenseActionTypes =
  | GetExpensesAction
  | UnsetExpensesAction
  | SetExpensesMetaAction
  | UnsetExpensesMetaAction
  | GetExpenseAction
  | CreateExpenseAction
  | UpdateExpenseAction
  | DeleteExpenseAction
  | ShowExpenseLoadingAction
  | HideExpenseLoadingAction
  | SetExpenseLastSavedDataAction;
