import { Reducer } from 'redux';
import { ExpenseState, ExpenseTypes, ExpenseActionTypes } from './types';

const initialState: ExpenseState = {
  expenses: [],
  lastSavedData: {
    date: '',
    account_id: 0
  }
};

type ReducerOptions = Reducer<ExpenseState, ExpenseActionTypes>;

const reducer: ReducerOptions = (state = initialState, action): ExpenseState => {
  switch (action.type) {
    case ExpenseTypes.GET_EXPENSES:
      return {
        ...state,
        expenses: action.payload
      };
    case ExpenseTypes.UNSET_EXPENSES:
      return {
        ...state,
        expenses: action.payload
      };
    case ExpenseTypes.SET_EXPENSES_META:
      return {
        ...state,
        meta: action.payload
      };
    case ExpenseTypes.UNSET_EXPENSES_META:
      return {
        ...state,
        meta: undefined
      };
    case ExpenseTypes.GET_EXPENSE:
      return {
        ...state,
        expense: action.payload
      };
    case ExpenseTypes.CREATE_EXPENSE:
      return {
        ...state,
        expense: action.payload
      };
    case ExpenseTypes.UPDATE_EXPENSE:
      return {
        ...state,
        expense: action.payload
      };
    case ExpenseTypes.DELETE_EXPENSE:
      return {
        ...state,
        expense: action.payload
      };
    case ExpenseTypes.SHOW_EXPENSE_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case ExpenseTypes.HIDE_EXPENSE_LOADING:
      return {
        ...state,
        loading: false
      };
    case ExpenseTypes.SET_EXPENSE_LAST_SAVED_DATA:
      return {
        ...state,
        lastSavedData: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
