import React, { createContext, useReducer, useEffect, Reducer } from 'react';
import { api } from '../../services/api';
import { OAuthActionTypes, OAuthProviderContextData, OAuthStateInterface, OAuthTypes, OAuthUserInterface } from './types';
import { useDispatch } from 'react-redux';
import { getProfile } from '../../redux/user/actions';

const initialState: OAuthStateInterface = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const reducer: Reducer<OAuthStateInterface, OAuthActionTypes> = (state, action) => {
  switch (action.type) {
    case OAuthTypes.OAUTH_INITIALIZE: {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialized: true,
        user
      };
    }

    case OAuthTypes.OAUTH_LOGIN: {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }

    case OAuthTypes.OAUTH_LOGOUT: {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }

    default:
      return state;
  }
};

const OAuthContext = createContext<OAuthProviderContextData>({
  ...initialState,
  platform: 'OAuth',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  accessToken: ''
});

const baseURL = process.env.REACT_APP_API_URL;

export const OAuthProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const storeDispatch = useDispatch();

  const handleGetMe = async (): Promise<OAuthUserInterface> => {
    return getProfile()(storeDispatch);
  };

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken) {
          await handleGetMe();

          dispatch({
            type: OAuthTypes.OAUTH_INITIALIZE,
            payload: {
              isAuthenticated: true,
              user: null
            }
          });
        } else {
          dispatch({
            type: OAuthTypes.OAUTH_INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        dispatch({
          type: OAuthTypes.OAUTH_INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = (email: string, password: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      api({
        ...(baseURL ? { baseURL } : {}),
        url: 'api/user/login',
        method: 'POST',
        data: {
          email,
          password
        }
      })
        .then(response => response.data)
        .then(async response => {
          localStorage.setItem('accessToken', response?.access_token);

          const user = await handleGetMe();

          dispatch({
            type: OAuthTypes.OAUTH_LOGIN,
            payload: {
              isAuthenticated: true,
              user
            }
          });

          resolve(response);
        })
        .catch(error => {
          if (error?.response && error?.response?.data) {
            reject(error.response.data);
          } else {
            reject(error);
          }
        });
    });
  };

  const logout = async (): Promise<void> => {
    await api.post('user/logout').then(() => {
      localStorage.removeItem('accessToken');

      dispatch({
        type: OAuthTypes.OAUTH_LOGOUT
      });
    });
  };

  return (
    <OAuthContext.Provider
      value={{
        ...state,
        platform: 'OAuth',
        login,
        logout,
        accessToken: window.localStorage.getItem('accessToken') || ''
      }}
    >
      {children}
    </OAuthContext.Provider>
  );
};

export default OAuthContext;
