import { IIncome, IIncomeLastSavedData, IPaginatorMeta } from '../../types';

export interface IncomeState {
  readonly incomes: IIncome[];
  readonly income?: IIncome;
  readonly meta?: IPaginatorMeta;
  readonly loading?: boolean;
  readonly lastSavedData: IIncomeLastSavedData;
}

export enum IncomeTypes {
  GET_INCOMES = 'GET_INCOMES',
  UNSET_INCOMES = 'UNSET_INCOMES',
  SET_INCOMES_META = 'SET_INCOMES_META',
  UNSET_INCOMES_META = 'UNSET_INCOMES_META',
  GET_INCOME = 'GET_INCOME',
  CREATE_INCOME = 'CREATE_INCOME',
  UPDATE_INCOME = 'UPDATE_INCOME',
  DELETE_INCOME = 'DELETE_INCOME',
  SHOW_INCOME_LOADING = 'SHOW_INCOME_LOADING',
  HIDE_INCOME_LOADING = 'HIDE_INCOME_LOADING',
  SET_INCOME_LAST_SAVED_DATA = 'SET_INCOME_LAST_SAVED_DATA'
}

export interface GetIncomesAction {
  type: typeof IncomeTypes.GET_INCOMES;
  payload: IIncome[];
}

export interface UnsetIncomesAction {
  type: typeof IncomeTypes.UNSET_INCOMES;
  payload: IIncome[];
}

export interface SetIncomesMetaAction {
  type: typeof IncomeTypes.SET_INCOMES_META;
  payload: IPaginatorMeta;
}

export interface UnsetIncomesMetaAction {
  type: typeof IncomeTypes.UNSET_INCOMES_META;
  payload: null;
}

export interface GetIncomeAction {
  type: typeof IncomeTypes.GET_INCOME;
  payload: IIncome;
}

export interface CreateIncomeAction {
  type: typeof IncomeTypes.CREATE_INCOME;
  payload: IIncome;
}

export interface UpdateIncomeAction {
  type: typeof IncomeTypes.UPDATE_INCOME;
  payload: IIncome;
}

export interface DeleteIncomeAction {
  type: typeof IncomeTypes.DELETE_INCOME;
  payload: IIncome;
}

export interface ShowIncomeLoadingAction {
  type: typeof IncomeTypes.SHOW_INCOME_LOADING;
  payload: true;
}

export interface HideIncomeLoadingAction {
  type: typeof IncomeTypes.HIDE_INCOME_LOADING;
}

export interface SetIncomeLastSavedDataAction {
  type: typeof IncomeTypes.SET_INCOME_LAST_SAVED_DATA;
  payload: IIncomeLastSavedData;
}

export type IncomeActionTypes =
  | GetIncomesAction
  | UnsetIncomesAction
  | SetIncomesMetaAction
  | UnsetIncomesMetaAction
  | GetIncomeAction
  | CreateIncomeAction
  | UpdateIncomeAction
  | DeleteIncomeAction
  | ShowIncomeLoadingAction
  | HideIncomeLoadingAction
  | SetIncomeLastSavedDataAction;
