import { INotification } from '../../types';

export interface NotificationState {
  readonly notification: INotification;
}

export enum NotificationTypes {
  SHOW_NOTIFICATION = 'SHOW_NOTIFICATION',
  HIDE_NOTIFICATION = 'HIDE_NOTIFICATION'
}

export interface ShowNotificationAction {
  type: typeof NotificationTypes.SHOW_NOTIFICATION;
  payload: INotification;
}

export interface HideNotificationAction {
  type: typeof NotificationTypes.HIDE_NOTIFICATION;
  payload: INotification;
}

export type NotificationActionTypes = ShowNotificationAction | HideNotificationAction;
