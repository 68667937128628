/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch } from 'react';
import { api } from '../../services/api';
import { UserActionTypes, UserTypes } from './types';
import { IUser, IUserQueryFilter } from '../../types';

export const setUsers = (data: IUser[]) => {
  return (dispatch: Dispatch<UserActionTypes>) => {
    dispatch({
      type: UserTypes.GET_USERS,
      payload: data
    });
  };
};

export const getUsers = (params?: IUserQueryFilter) => (dispatch: Dispatch<UserActionTypes>) => {
  return new Promise((resolve: any, reject: any) => {
    dispatch({
      type: UserTypes.UNSET_USERS,
      payload: []
    });
    dispatch({
      type: UserTypes.UNSET_USERS_META,
      payload: null
    });
    dispatch({
      type: UserTypes.SHOW_USER_LOADING,
      payload: true
    });
    api
      .get('/users', {
        params
      })
      .then(response => response.data)
      .then(response => {
        const { data, meta } = response;
        dispatch({
          type: UserTypes.GET_USERS,
          payload: data
        });
        dispatch({
          type: UserTypes.SET_USERS_META,
          payload: {
            currentPage: meta.current_page,
            from: meta.from,
            lastPage: meta.last_page,
            perPage: meta.per_page,
            to: meta.to,
            total: meta.total,
            links: meta.links
          }
        });
        dispatch({
          type: UserTypes.HIDE_USER_LOADING
        });
        resolve(data);
      })
      .catch((error: any) => {
        dispatch({
          type: UserTypes.HIDE_USER_LOADING
        });
        if (error?.response && error?.response?.data) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });
};

export const getUsersList = () => (dispatch: Dispatch<UserActionTypes>) => {
  return new Promise((resolve: any, reject: any) => {
    dispatch({
      type: UserTypes.UNSET_USERS_LIST,
      payload: []
    });
    api
      .get('/users/list')
      .then(response => response.data)
      .then(response => {
        const { data } = response;
        dispatch({
          type: UserTypes.GET_USERS_LIST,
          payload: data
        });
        resolve(data);
      })
      .catch((error: any) => {
        if (error?.response && error?.response?.data) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });
};

export const getUser = (id: number) => (dispatch: Dispatch<UserActionTypes>) => {
  return new Promise((resolve: any, reject: any) => {
    dispatch({
      type: UserTypes.SHOW_USER_LOADING,
      payload: true
    });
    api
      .get(`/users/${id}`)
      .then(response => response.data)
      .then(response => {
        const { data } = response;
        dispatch({
          type: UserTypes.GET_USER,
          payload: data
        });
        dispatch({
          type: UserTypes.HIDE_USER_LOADING
        });
        resolve(data);
      })
      .catch((error: any) => {
        dispatch({
          type: UserTypes.HIDE_USER_LOADING
        });
        if (error?.response && error?.response?.data) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });
};

export const getProfile = () => {
  return async (dispatch: Dispatch<UserActionTypes>) => {
    dispatch({
      type: UserTypes.SHOW_USER_LOADING,
      payload: true
    });
    return api
      .get('/user')
      .then(res => res.data)
      .then(res => {
        const { data } = res;
        dispatch({
          type: UserTypes.GET_PROFILE,
          payload: data
        });
        dispatch({
          type: UserTypes.HIDE_USER_LOADING
        });
        return res;
      })
      .catch(() => {
        dispatch({
          type: UserTypes.HIDE_USER_LOADING
        });
      });
  };
};

export const createUser = (params: any) => () => {
  return new Promise((resolve: any, reject: any) => {
    api
      .post('/users', params)
      .then((response: any) => {
        if (response?.data) {
          resolve(response.data.data);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error: any) => {
        if (error?.response && error?.response?.data) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });
};

export const updateUser = (id: number, params: any) => () => {
  return new Promise((resolve: any, reject: any) => {
    api
      .put(`/users/${id}`, params)
      .then((response: any) => {
        if (response?.data) {
          resolve(response.data.data);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error: any) => {
        if (error?.response && error?.response?.data) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });
};

export const toggleUserStatus = (id: number) => () => {
  return new Promise((resolve: any, reject: any) => {
    api
      .put(`/users/${id}/toggle-status`)
      .then((response: any) => {
        if (response?.data) {
          resolve(response.data.data);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error: any) => {
        if (error?.response && error?.response?.data) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });
};

export const deleteUsers = (ids: number[]) => () => {
  return new Promise((resolve: any, reject: any) => {
    api
      .delete('/users', {
        params: {
          ids: ids
        }
      })
      .then(response => {
        if (response?.status === 204) {
          resolve();
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        if (error?.response && error?.response?.data) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });
};
