import { Reducer } from 'redux';
import { DashboardState, DashboardTypes, DashboardActionTypes } from './types';

const initialState: DashboardState = {
  accountsStatistics: []
};

type ReducerOptions = Reducer<DashboardState, DashboardActionTypes>;

const reducer: ReducerOptions = (state = initialState, action): DashboardState => {
  switch (action.type) {
    case DashboardTypes.GET_ACCOUNTS_STATISTICS:
      return {
        ...state,
        accountsStatistics: action.payload
      };
    case DashboardTypes.SHOW_DASHBOARD_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case DashboardTypes.HIDE_DASHBOARD_LOADING:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export default reducer;
