import { IBreadcrumb } from '../../types';

export interface BreadcrumbState {
  readonly breadcrumbs: IBreadcrumb[];
}

export enum BreadcrumbTypes {
  SET_BREADCRUMBS = 'SET_BREADCRUMBS'
}

export interface SetBreadcrumbsAction {
  type: typeof BreadcrumbTypes.SET_BREADCRUMBS;
  payload: IBreadcrumb[];
}

export type BreadcrumbActionTypes = SetBreadcrumbsAction;
