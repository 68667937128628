import { ITransfer, IPaginatorMeta } from '../../types';

export interface TransferState {
  readonly transfers: ITransfer[];
  readonly transfersList: ITransfer[];
  readonly transfer?: ITransfer;
  readonly meta?: IPaginatorMeta;
  readonly loading?: boolean;
}

export enum TransferTypes {
  GET_TRANSFERS = 'GET_TRANSFERS',
  UNSET_TRANSFERS = 'UNSET_TRANSFERS',
  SET_TRANSFERS_META = 'SET_TRANSFERS_META',
  UNSET_TRANSFERS_META = 'UNSET_TRANSFERS_META',
  GET_TRANSFERS_LIST = 'GET_TRANSFERS_LIST',
  UNSET_TRANSFERS_LIST = 'UNSET_TRANSFERS_LIST',
  GET_TRANSFER = 'GET_TRANSFER',
  CREATE_TRANSFER = 'CREATE_TRANSFER',
  UPDATE_TRANSFER = 'UPDATE_TRANSFER',
  DELETE_TRANSFER = 'DELETE_TRANSFER',
  SHOW_TRANSFER_LOADING = 'SHOW_TRANSFER_LOADING',
  HIDE_TRANSFER_LOADING = 'HIDE_TRANSFER_LOADING'
}

export interface GetTransfersAction {
  type: typeof TransferTypes.GET_TRANSFERS;
  payload: ITransfer[];
}

export interface UnsetTransfersAction {
  type: typeof TransferTypes.UNSET_TRANSFERS;
  payload: ITransfer[];
}

export interface SetTransfersMetaAction {
  type: typeof TransferTypes.SET_TRANSFERS_META;
  payload: IPaginatorMeta;
}

export interface UnsetTransfersMetaAction {
  type: typeof TransferTypes.UNSET_TRANSFERS_META;
  payload: null;
}

export interface GetTransfersListAction {
  type: typeof TransferTypes.GET_TRANSFERS_LIST;
  payload: ITransfer[];
}

export interface UnsetTransfersListAction {
  type: typeof TransferTypes.UNSET_TRANSFERS_LIST;
  payload: ITransfer[];
}

export interface GetTransferAction {
  type: typeof TransferTypes.GET_TRANSFER;
  payload: ITransfer;
}

export interface CreateTransferAction {
  type: typeof TransferTypes.CREATE_TRANSFER;
  payload: ITransfer;
}

export interface UpdateTransferAction {
  type: typeof TransferTypes.UPDATE_TRANSFER;
  payload: ITransfer;
}

export interface DeleteTransferAction {
  type: typeof TransferTypes.DELETE_TRANSFER;
  payload: ITransfer;
}

export interface ShowTransferLoadingAction {
  type: typeof TransferTypes.SHOW_TRANSFER_LOADING;
  payload: true;
}

export interface HideTransferLoadingAction {
  type: typeof TransferTypes.HIDE_TRANSFER_LOADING;
}

export type TransferActionTypes =
  | GetTransfersAction
  | UnsetTransfersAction
  | SetTransfersMetaAction
  | UnsetTransfersMetaAction
  | GetTransfersListAction
  | UnsetTransfersListAction
  | GetTransferAction
  | CreateTransferAction
  | UpdateTransferAction
  | DeleteTransferAction
  | ShowTransferLoadingAction
  | HideTransferLoadingAction;
