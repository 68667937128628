import { ICurrency } from '../../types';

export interface CurrencyState {
  readonly currencies: ICurrency[];
  readonly loading?: boolean;
}

export enum CurrencyTypes {
  GET_CURRENCIES = 'GET_CURRENCIES',
  CLEAR_CURRENCIES = 'CLEAR_CURRENCIES',
  SHOW_CURRENCY_LOADING = 'SHOW_CURRENCY_LOADING',
  HIDE_CURRENCY_LOADING = 'HIDE_CURRENCY_LOADING'
}

export interface GetCurrenciesAction {
  type: typeof CurrencyTypes.GET_CURRENCIES;
  payload: ICurrency[];
}

export interface ClearCurrenciesAction {
  type: typeof CurrencyTypes.CLEAR_CURRENCIES;
  payload: ICurrency[];
}

export interface ShowCurrencyLoadingAction {
  type: typeof CurrencyTypes.SHOW_CURRENCY_LOADING;
  payload: true;
}

export interface HideCurrencyLoadingAction {
  type: typeof CurrencyTypes.HIDE_CURRENCY_LOADING;
}

export type CurrencyActionTypes =
  | GetCurrenciesAction
  | ClearCurrenciesAction
  | ShowCurrencyLoadingAction
  | HideCurrencyLoadingAction;
