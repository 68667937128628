import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Preloader from '../components/Preloader';
import DefaultLayout from '../layouts/DefaultLayout';

const Login = lazy(() => import('../pages/login'));
const Profile = lazy(() => import('../pages/profile'));
const Dashboard = lazy(() => import('../pages/dashboard'));
const Accounts = lazy(() => import('../pages/accounts'));
const Categories = lazy(() => import('../pages/categories'));
const Subcategories = lazy(() => import('../pages/subcategories'));
const Expenses = lazy(() => import('../pages/expenses'));
const Incomes = lazy(() => import('../pages/incomes'));
const Transfers = lazy(() => import('../pages/transfers'));
const Reports = lazy(() => import('../pages/reports'));
const AccountReport = lazy(() => import('../pages/reports/accounts'));
const BalanceReport = lazy(() => import('../pages/reports/balance'));
const CategoryReport = lazy(() => import('../pages/reports/categories'));
const SubcategoryReport = lazy(() => import('../pages/reports/subcategories'));

const AppRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Preloader />}>
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route path="login" element={<Login />} />
          <Route element={<DefaultLayout />}>
            <Route path="profile" element={<Profile />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="accounts" element={<Accounts />} />
            <Route path="categories" element={<Categories />} />
            <Route path="categories/:categoryId/subcategories" element={<Subcategories />} />
            <Route path="expenses" element={<Expenses />} />
            <Route path="incomes" element={<Incomes />} />
            <Route path="transfers" element={<Transfers />} />
            <Route path="reports" element={<Reports />} />
            <Route path="reports/accounts" element={<AccountReport />} />
            <Route path="reports/balance" element={<BalanceReport />} />
            <Route path="reports/categories" element={<CategoryReport />} />
            <Route path="reports/subcategories" element={<SubcategoryReport />} />
          </Route>
          <Route path="*" element={<p>404!</p>} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default AppRoutes;
