import { Reducer } from 'redux';
import { ReportState, ReportTypes, ReportActionTypes } from './types';

const initialState: ReportState = {
  accountReport: [],
  categoryReport: [],
  categoryReportTotal: ''
};

type ReducerOptions = Reducer<ReportState, ReportActionTypes>;

const reducer: ReducerOptions = (state = initialState, action): ReportState => {
  switch (action.type) {
    case ReportTypes.SET_ACCOUNT:
      return {
        ...state,
        account: action.payload
      };
    case ReportTypes.UNSET_ACCOUNT:
      return {
        ...state
      };
    case ReportTypes.SET_ACCOUNT_REPORT:
      return {
        ...state,
        accountReport: action.payload
      };
    case ReportTypes.UNSET_ACCOUNT_REPORT:
      return {
        ...state,
        accountReport: action.payload
      };
    case ReportTypes.SET_ACCOUNT_REPORT_ENTRY:
      return {
        ...state,
        accountReportEntry: action.payload
      };
    case ReportTypes.UNSET_ACCOUNT_REPORT_ENTRY:
      return {
        ...state
      };
    case ReportTypes.SET_CATEGORY_REPORT:
      return {
        ...state,
        categoryReport: action.payload
      };
    case ReportTypes.UNSET_CATEGORY_REPORT:
      return {
        ...state,
        categoryReport: action.payload
      };
    case ReportTypes.SET_CATEGORY_REPORT_TOTAL:
      return {
        ...state,
        categoryReportTotal: action.payload
      };
    case ReportTypes.UNSET_CATEGORY_REPORT_TOTAL:
      return {
        ...state,
        categoryReportTotal: action.payload
      };
    case ReportTypes.SHOW_REPORT_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case ReportTypes.HIDE_REPORT_LOADING:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export default reducer;
