import { applyMiddleware, combineReducers, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import ReduxThunk from 'redux-thunk';

import { BreadcrumbState } from './breadcrumb/types';
import { NotificationState } from './notification/types';
import { UserState } from './user/types';
import { DashboardState } from './dashboard/types';
import { CurrencyState } from './currency/types';
import { AccountState } from './account/types';
import { CategoryState } from './category/types';
import { SubcategoryState } from './subcategory/types';
import { ExpenseState } from './expense/types';
import { IncomeState } from './income/types';
import { TransferState } from './transfer/types';
import { ReportState } from './report/types';

import breadcrumbReducer from './breadcrumb/reducer';
import notificationReducer from './notification/reducer';
import userReducer from './user/reducer';
import dashboardReducer from './dashboard/reducer';
import currencyReducer from './currency/reducer';
import accountReducer from './account/reducer';
import categoryReducer from './category/reducer';
import subcategoryReducer from './subcategory/reducer';
import expenseReducer from './expense/reducer';
import incomeReducer from './income/reducer';
import transferReducer from './transfer/reducer';
import reportReducer from './report/reducer';

export const rootReducer = combineReducers({
  breadcrumbReducer,
  notificationReducer,
  userReducer,
  dashboardReducer,
  currencyReducer,
  accountReducer,
  categoryReducer,
  subcategoryReducer,
  expenseReducer,
  incomeReducer,
  transferReducer,
  reportReducer
});

export type RootState = ReturnType<typeof rootReducer>;

/**
 * Application Redux Interface
 *
 * @export
 * @interface ApplicationState
 */
export interface ApplicationState {
  breadcrumbReducer: BreadcrumbState;
  notificationReducer: NotificationState;
  userReducer: UserState;
  dashboardReducer: DashboardState;
  currencyReducer: CurrencyState;
  accountReducer: AccountState;
  categoryReducer: CategoryState;
  subcategoryReducer: SubcategoryState;
  expenseReducer: ExpenseState;
  incomeReducer: IncomeState;
  transferReducer: TransferState;
  reportReducer: ReportState;
}

const store: Store<ApplicationState> = createStore(rootReducer, {}, composeWithDevTools(applyMiddleware(ReduxThunk)));

export default store;
