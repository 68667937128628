import { Reducer } from 'redux';
import { SubcategoryState, SubcategoryTypes, SubcategoryActionTypes } from './types';

const initialState: SubcategoryState = {
  subcategories: [],
  subcategoriesList: []
};

type ReducerOptions = Reducer<SubcategoryState, SubcategoryActionTypes>;

const reducer: ReducerOptions = (state = initialState, action): SubcategoryState => {
  switch (action.type) {
    case SubcategoryTypes.GET_SUBCATEGORIES:
      return {
        ...state,
        subcategories: action.payload
      };
    case SubcategoryTypes.UNSET_SUBCATEGORIES:
      return {
        ...state,
        subcategories: action.payload
      };
    case SubcategoryTypes.SET_SUBCATEGORIES_META:
      return {
        ...state,
        meta: action.payload
      };
    case SubcategoryTypes.UNSET_SUBCATEGORIES_META:
      return {
        ...state,
        meta: undefined
      };
    case SubcategoryTypes.GET_SUBCATEGORIES_LIST:
      return {
        ...state,
        subcategoriesList: action.payload
      };
    case SubcategoryTypes.UNSET_SUBCATEGORIES_LIST:
      return {
        ...state,
        subcategoriesList: action.payload
      };
    case SubcategoryTypes.GET_SUBCATEGORY:
      return {
        ...state,
        subcategory: action.payload
      };
    case SubcategoryTypes.CREATE_SUBCATEGORY:
      return {
        ...state,
        subcategory: action.payload
      };
    case SubcategoryTypes.UPDATE_SUBCATEGORY:
      return {
        ...state,
        subcategory: action.payload
      };
    case SubcategoryTypes.DELETE_SUBCATEGORY:
      return {
        ...state,
        subcategory: action.payload
      };
    case SubcategoryTypes.SHOW_SUBCATEGORY_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case SubcategoryTypes.HIDE_SUBCATEGORY_LOADING:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export default reducer;
