import { Reducer } from 'redux';
import { BreadcrumbState, BreadcrumbTypes, BreadcrumbActionTypes } from './types';

const initialState: BreadcrumbState = {
  breadcrumbs: []
};

type ReducerOptions = Reducer<BreadcrumbState, BreadcrumbActionTypes>;

const reducer: ReducerOptions = (state = initialState, action): BreadcrumbState => {
  switch (action.type) {
    case BreadcrumbTypes.SET_BREADCRUMBS:
      return {
        ...state,
        breadcrumbs: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
