import { Reducer } from 'redux';
import { NotificationState, NotificationTypes, NotificationActionTypes } from './types';

const initialState: NotificationState = {
  notification: {
    type: 'info',
    title: '',
    message: '',
    timeout: 8000,
    show: false
  }
};

type ReducerOptions = Reducer<NotificationState, NotificationActionTypes>;

const reducer: ReducerOptions = (state = initialState, action): NotificationState => {
  switch (action.type) {
    case NotificationTypes.SHOW_NOTIFICATION:
      return {
        ...state,
        notification: {
          ...state.notification,
          ...action.payload
        }
      };
    case NotificationTypes.HIDE_NOTIFICATION:
      return {
        ...state,
        notification: {
          ...state.notification,
          ...action.payload
        }
      };
    default:
      return state;
  }
};

export default reducer;
