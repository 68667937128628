import { IUser, IPaginatorMeta } from '../../types';

export interface UserState {
  readonly users: IUser[];
  readonly usersList: IUser[];
  readonly user?: IUser;
  readonly profile?: IUser;
  readonly meta?: IPaginatorMeta;
  readonly loading?: boolean;
}

export enum UserTypes {
  GET_USERS = 'GET_USERS',
  UNSET_USERS = 'UNSET_USERS',
  SET_USERS_META = 'SET_USERS_META',
  UNSET_USERS_META = 'UNSET_USERS_META',
  GET_USERS_LIST = 'GET_USERS_LIST',
  UNSET_USERS_LIST = 'UNSET_USERS_LIST',
  GET_USER = 'GET_USER',
  GET_PROFILE = 'GET_PROFILE',
  CREATE_USER = 'CREATE_USER',
  UPDATE_USER = 'UPDATE_USER',
  DELETE_USER = 'DELETE_USER',
  SHOW_USER_LOADING = 'SHOW_USER_LOADING',
  HIDE_USER_LOADING = 'HIDE_USER_LOADING'
}

export interface GetUsersAction {
  type: typeof UserTypes.GET_USERS;
  payload: IUser[];
}

export interface UnsetUsersAction {
  type: typeof UserTypes.UNSET_USERS;
  payload: IUser[];
}

export interface SetUsersMetaAction {
  type: typeof UserTypes.SET_USERS_META;
  payload: IPaginatorMeta;
}

export interface UnsetUsersMetaAction {
  type: typeof UserTypes.UNSET_USERS_META;
  payload: null;
}

export interface GetUsersListAction {
  type: typeof UserTypes.GET_USERS_LIST;
  payload: IUser[];
}

export interface UnsetUsersListAction {
  type: typeof UserTypes.UNSET_USERS_LIST;
  payload: IUser[];
}

export interface GetUserAction {
  type: typeof UserTypes.GET_USER;
  payload: IUser;
}

export interface GetProfileAction {
  type: typeof UserTypes.GET_PROFILE;
  payload: IUser;
}

export interface CreateUserAction {
  type: typeof UserTypes.CREATE_USER;
  payload: IUser;
}

export interface UpdateUserAction {
  type: typeof UserTypes.UPDATE_USER;
  payload: IUser;
}

export interface DeleteUserAction {
  type: typeof UserTypes.DELETE_USER;
  payload: IUser;
}

export interface ShowUserLoadingAction {
  type: typeof UserTypes.SHOW_USER_LOADING;
  payload: true;
}

export interface HideUserLoadingAction {
  type: typeof UserTypes.HIDE_USER_LOADING;
}

export type UserActionTypes =
  | GetUsersAction
  | UnsetUsersAction
  | SetUsersMetaAction
  | UnsetUsersMetaAction
  | GetUsersListAction
  | UnsetUsersListAction
  | GetUserAction
  | GetProfileAction
  | CreateUserAction
  | UpdateUserAction
  | DeleteUserAction
  | ShowUserLoadingAction
  | HideUserLoadingAction;
