import { Reducer } from 'redux';
import { TransferState, TransferTypes, TransferActionTypes } from './types';

const initialState: TransferState = {
  transfers: [],
  transfersList: []
};

type ReducerOptions = Reducer<TransferState, TransferActionTypes>;

const reducer: ReducerOptions = (state = initialState, action): TransferState => {
  switch (action.type) {
    case TransferTypes.GET_TRANSFERS:
      return {
        ...state,
        transfers: action.payload
      };
    case TransferTypes.UNSET_TRANSFERS:
      return {
        ...state,
        transfers: action.payload
      };
    case TransferTypes.SET_TRANSFERS_META:
      return {
        ...state,
        meta: action.payload
      };
    case TransferTypes.UNSET_TRANSFERS_META:
      return {
        ...state,
        meta: undefined
      };
    case TransferTypes.GET_TRANSFERS_LIST:
      return {
        ...state,
        transfersList: action.payload
      };
    case TransferTypes.UNSET_TRANSFERS_LIST:
      return {
        ...state,
        transfersList: action.payload
      };
    case TransferTypes.GET_TRANSFER:
      return {
        ...state,
        transfer: action.payload
      };
    case TransferTypes.CREATE_TRANSFER:
      return {
        ...state,
        transfer: action.payload
      };
    case TransferTypes.UPDATE_TRANSFER:
      return {
        ...state,
        transfer: action.payload
      };
    case TransferTypes.DELETE_TRANSFER:
      return {
        ...state,
        transfer: action.payload
      };
    case TransferTypes.SHOW_TRANSFER_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case TransferTypes.HIDE_TRANSFER_LOADING:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export default reducer;
