import React, { Fragment } from 'react';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon
} from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { Transition } from '@headlessui/react';
import { hideNotification } from '../../redux/notification/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../redux';
import useTimeout from '../../hooks/useTimeout';

const Notification: React.FC = () => {
  const dispatch = useDispatch();
  const notification = useSelector((state: ApplicationState) => state.notificationReducer.notification);
  const timeOut = Number(notification?.timeout) || 8000;

  useTimeout(() => {
    if (notification?.message && timeOut > 0) {
      dispatch(hideNotification());
    }
  }, timeOut);

  return (
    <>
      <div
        aria-live="assertive"
        className="z-50 fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-end"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          <Transition
            show={notification?.show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-md pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    {notification?.type === 'success' && (
                      <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                    )}
                    {notification?.type === 'warning' && (
                      <ExclamationTriangleIcon className="h-6 w-6 text-yellow-500" aria-hidden="true" />
                    )}
                    {notification?.type === 'error' && (
                      <ExclamationCircleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    )}
                    {notification?.type === 'info' && (
                      <InformationCircleIcon className="h-6 w-6 text-gray-400 dark:text-gray-200" aria-hidden="true" />
                    )}
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    {notification?.title && <p className="text-sm font-medium text-gray-900">{notification?.title}</p>}
                    {!notification?.title && notification?.message && (
                      <p className="text-sm font-medium text-gray-900">{notification?.message}</p>
                    )}
                    {notification?.title && notification?.message && (
                      <p className="mt-1 text-sm text-gray-500">{notification?.message}</p>
                    )}
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => hideNotification()(dispatch)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
};

export default Notification;
