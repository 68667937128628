import { ICategory, IPaginatorMeta } from '../../types';

export interface CategoryState {
  readonly categories: ICategory[];
  readonly categoriesList: ICategory[];
  readonly category?: ICategory;
  readonly meta?: IPaginatorMeta;
  readonly loading?: boolean;
}

export enum CategoryTypes {
  GET_CATEGORIES = 'GET_CATEGORIES',
  UNSET_CATEGORIES = 'UNSET_CATEGORIES',
  SET_CATEGORIES_META = 'SET_CATEGORIES_META',
  UNSET_CATEGORIES_META = 'UNSET_CATEGORIES_META',
  GET_CATEGORIES_LIST = 'GET_CATEGORIES_LIST',
  UNSET_CATEGORIES_LIST = 'UNSET_CATEGORIES_LIST',
  GET_CATEGORY = 'GET_CATEGORY',
  CREATE_CATEGORY = 'CREATE_CATEGORY',
  UPDATE_CATEGORY = 'UPDATE_CATEGORY',
  DELETE_CATEGORY = 'DELETE_CATEGORY',
  SHOW_CATEGORY_LOADING = 'SHOW_CATEGORY_LOADING',
  HIDE_CATEGORY_LOADING = 'HIDE_CATEGORY_LOADING'
}

export interface GetCategoriesAction {
  type: typeof CategoryTypes.GET_CATEGORIES;
  payload: ICategory[];
}

export interface UnsetCategoriesAction {
  type: typeof CategoryTypes.UNSET_CATEGORIES;
  payload: ICategory[];
}

export interface SetCategoriesMetaAction {
  type: typeof CategoryTypes.SET_CATEGORIES_META;
  payload: IPaginatorMeta;
}

export interface UnsetCategoriesMetaAction {
  type: typeof CategoryTypes.UNSET_CATEGORIES_META;
  payload: null;
}

export interface GetCategoriesListAction {
  type: typeof CategoryTypes.GET_CATEGORIES_LIST;
  payload: ICategory[];
}

export interface UnsetCategoriesListAction {
  type: typeof CategoryTypes.UNSET_CATEGORIES_LIST;
  payload: ICategory[];
}

export interface GetCategoryAction {
  type: typeof CategoryTypes.GET_CATEGORY;
  payload: ICategory;
}

export interface CreateCategoryAction {
  type: typeof CategoryTypes.CREATE_CATEGORY;
  payload: ICategory;
}

export interface UpdateCategoryAction {
  type: typeof CategoryTypes.UPDATE_CATEGORY;
  payload: ICategory;
}

export interface DeleteCategoryAction {
  type: typeof CategoryTypes.DELETE_CATEGORY;
  payload: ICategory;
}

export interface ShowCategoryLoadingAction {
  type: typeof CategoryTypes.SHOW_CATEGORY_LOADING;
  payload: true;
}

export interface HideCategoryLoadingAction {
  type: typeof CategoryTypes.HIDE_CATEGORY_LOADING;
}

export type CategoryActionTypes =
  | GetCategoriesAction
  | UnsetCategoriesAction
  | SetCategoriesMetaAction
  | UnsetCategoriesMetaAction
  | GetCategoriesListAction
  | UnsetCategoriesListAction
  | GetCategoryAction
  | CreateCategoryAction
  | UpdateCategoryAction
  | DeleteCategoryAction
  | ShowCategoryLoadingAction
  | HideCategoryLoadingAction;
