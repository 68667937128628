// eslint-disable-next-line @typescript-eslint/ban-types
export type OAuthProviderInterface = {};

export interface OAuthProviderContextData extends OAuthStateInterface {
  platform: 'OAuth';
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
  accessToken: string;
}

export interface OAuthUserInterface {
  id: number;
  uid: string;
  first_name: string;
  last_name: string;
  email: string;
  photo: string;
  status: boolean;
}

export interface OAuthStateInterface {
  readonly isAuthenticated: boolean;
  readonly isInitialized: boolean;
  readonly user: OAuthUserInterface | null;
}

export enum OAuthTypes {
  OAUTH_INITIALIZE = 'OAUTH_INITIALIZE',
  OAUTH_LOGIN = 'OAUTH_LOGIN',
  OAUTH_LOGOUT = 'OAUTH_LOGOUT'
}

export interface OAuthInitializeAction {
  type: typeof OAuthTypes.OAUTH_INITIALIZE;
  payload: {
    isAuthenticated: boolean;
    user: OAuthUserInterface | null;
  };
}

export interface OAuthLoginAction {
  type: typeof OAuthTypes.OAUTH_LOGIN;
  payload: {
    isAuthenticated: boolean;
    user: OAuthUserInterface;
  };
}

export interface OAuthLogoutAction {
  type: typeof OAuthTypes.OAUTH_LOGOUT;
}

export type OAuthActionTypes = OAuthInitializeAction | OAuthLoginAction | OAuthLogoutAction;
