import React, { useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Notification from '../components/Notification';

export interface IRouterProps {
  children?: JSX.Element;
}

const DefaultLayout: React.FC<IRouterProps> = ({ children }) => {
  const { isAuthenticated, isInitialized } = useAuth();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();

  if (!isInitialized) return null;

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children ? (
    children
  ) : (
    <>
      <div>
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <Notification />
        <div className="md:pl-64 flex flex-col">
          <Header setSidebarOpen={setSidebarOpen} />
          <main className="flex-1 relative focus:outline-none">
            <Outlet />
          </main>
        </div>
      </div>
    </>
  );
};

export default DefaultLayout;
