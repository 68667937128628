import { Reducer } from 'redux';
import { IncomeState, IncomeTypes, IncomeActionTypes } from './types';

const initialState: IncomeState = {
  incomes: [],
  lastSavedData: {
    date: '',
    account_id: 0
  }
};

type ReducerOptions = Reducer<IncomeState, IncomeActionTypes>;

const reducer: ReducerOptions = (state = initialState, action): IncomeState => {
  switch (action.type) {
    case IncomeTypes.GET_INCOMES:
      return {
        ...state,
        incomes: action.payload
      };
    case IncomeTypes.UNSET_INCOMES:
      return {
        ...state,
        incomes: action.payload
      };
    case IncomeTypes.SET_INCOMES_META:
      return {
        ...state,
        meta: action.payload
      };
    case IncomeTypes.UNSET_INCOMES_META:
      return {
        ...state,
        meta: undefined
      };
    case IncomeTypes.GET_INCOME:
      return {
        ...state,
        income: action.payload
      };
    case IncomeTypes.CREATE_INCOME:
      return {
        ...state,
        income: action.payload
      };
    case IncomeTypes.UPDATE_INCOME:
      return {
        ...state,
        income: action.payload
      };
    case IncomeTypes.DELETE_INCOME:
      return {
        ...state,
        income: action.payload
      };
    case IncomeTypes.SHOW_INCOME_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case IncomeTypes.HIDE_INCOME_LOADING:
      return {
        ...state,
        loading: false
      };
    case IncomeTypes.SET_INCOME_LAST_SAVED_DATA:
      return {
        ...state,
        lastSavedData: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
