import { Dispatch } from 'react';
import { NotificationActionTypes, NotificationTypes } from './types';
import { INotification } from '../../types';

export const showNotification = (notification: INotification) => {
  return (dispatch: Dispatch<NotificationActionTypes>) => {
    dispatch({
      type: NotificationTypes.SHOW_NOTIFICATION,
      payload: { ...notification, show: true }
    });
  };
};

export const hideNotification = (notification?: INotification) => {
  return (dispatch: Dispatch<NotificationActionTypes>) => {
    dispatch({
      type: NotificationTypes.HIDE_NOTIFICATION,
      payload: { ...notification, show: false }
    });
  };
};
