import { Reducer } from 'redux';
import { CurrencyState, CurrencyTypes, CurrencyActionTypes } from './types';

const initialState: CurrencyState = {
  currencies: []
};

type ReducerOptions = Reducer<CurrencyState, CurrencyActionTypes>;

const reducer: ReducerOptions = (state = initialState, action): CurrencyState => {
  switch (action.type) {
    case CurrencyTypes.GET_CURRENCIES:
      return {
        ...state,
        currencies: action.payload
      };
    case CurrencyTypes.CLEAR_CURRENCIES:
      return {
        ...state,
        currencies: action.payload
      };
    case CurrencyTypes.SHOW_CURRENCY_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case CurrencyTypes.HIDE_CURRENCY_LOADING:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export default reducer;
